.moving-img {
    width: 30rem;
}

.cabinet-sec{
    z-index: 2;
    position: relative;
}

.cabinet-sec a {
    display: block;
    width: fit-content;
}

.design-divs {
    width: calc(33.33333333333333333333% - 0.6rem);
}

.design-videos{
    aspect-ratio: 4/3;
    width: 100%;
    object-fit: cover;
}

@media (max-width: 1024px) {
.creative-minds {
    font-size: 3.5rem;
    line-height: 1;
}
}


@media (max-width: 768px) {
    .moving-img {
        width: 30rem;
    }
    .div-moving-img {
        right: 3rem;
        top: 15rem;
    }

    .design-divs {
        width: calc(50% - 0.3rem);
    }
}

@media (max-width: 550px) {
    .moving-img {
        width: 20rem;
    }
    .div-moving-img {
        right: 1rem;
        top: 15rem;
    }
    .cabinet-title {
        padding-bottom: 3rem;
      }
}

@media (max-width: 330px) {
    .moving-img {
        display: none;
    }
}