.ps {
    font-family: Dolce;
    padding-left: 3rem;
    padding-right: 3rem;
    display: grid;
    grid-template-columns: 1fr 1fr;
    gap: 1rem;
    width: 100%;
  }
  
  #ps {
    -webkit-transition: color 1000ms linear;
    -moz-transition: color 1000ms linear;
    -o-transition: color 1000ms linear;
    -ms-transition: color 1000ms linear;
    transition: color 1000ms linear;
  }
  .ps h1 {
    font-size: calc(3rem + 1vw);
    line-height: calc(2.8rem + 1vw);
  }
  
  .view-all {
      height: 3rem;
      width: 10rem;
      margin-left: 1rem;
      display: flex;
      justify-content: center;
      align-items: center;
  }
  
  .tv-fx {
    margin-left: auto;
    width: -webkit-fill-available;
  
  }
  
  .static {
    object-fit: cover;
    border-radius: 1rem;
    height: 50vh;
    width: 100%;
    border: 1px solid;
  }
  
  .work-tv {
    font-size: 4rem;
  }
  