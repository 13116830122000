@tailwind base;
@tailwind components;
@tailwind utilities;

html {
  scroll-behavior: cubic-bezier(0.25, 0.46, 0.45, 0.94);
}

body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  overflow: overlay;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}

/* width */
::-webkit-scrollbar {
  width: 0.4rem;
  
}

/* Track */
::-webkit-scrollbar-track {
  background: rgba(0, 0, 0, 0.2);
}
 
/* Handle */
::-webkit-scrollbar-thumb {
  background: #888; 
  border-radius: 2rem
}

.text-white {
  color: #f6f4f3;
}

.text-black {
  color: #030301;
}


.bg-white {
  background-color: #f6f4f3;
}

.bg-black {
  background-color: #030301;
}

.text-xs {
  font-size: 0.65rem;
}

.LazyLoad {
  opacity: 0;
  transition: all 1s ease-in-out;
}

.is-visible {
  opacity: 1;
}