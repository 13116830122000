.workHome {
    padding-top: 5rem;
    font-size: 15rem;
}

.work-sec{
    z-index: 2;
    position: relative;
}

.work-sec a {
    display: block;
    width: fit-content;
}

@media (max-width: 425px) {
.workHome {
    padding-top: 20rem;
    font-size: 6rem;
}
}

.square {
    aspect-ratio: 1/1;
    display: block;
    // Fallback
    @supports not (aspect-ratio: 1 / 1) {
    min-width: 100px;
    min-height: 100px;
    max-height: 1080px;
    max-width: 1080px;
    display: block;
  }
}