.st0{fill:none;stroke:#f6f4f3;stroke-width:5;stroke-miterlimit:10;}


//work animation
.work {
    height: 100%;
    z-index: 1;
    
  }
  
  .w-svg {
    height: 100vh;
    overflow: hidden;
    display: flex;
    justify-content: center;
    align-items: center;
    position: sticky;
    position: -webkit-sticky;
    top: 0;
    width: 100%;
  mix-blend-mode: difference;
    z-index: 0;
    scale: 0.8;
  }
  
  .work1 {
    height: 100%;
  }

  .w-work {
    height: 200vh;
    position: relative;
  }

  //portfolio
  .w-portfolio {
    position: relative;
    height: 100%;
  }
  
  .w-portfolio a{
    display: block;
  }
  
  .hs-wrapper {
    position: relative;
    height: calc(100vh - 22vh);
    aspect-ratio: 16/9;
    width: fit-content;
  }  

  .hs-wrapper video{
    width: 100%;
    height: 100%;
      -o-object-position: center;
      object-position: center;
      -o-object-fit: cover;
      object-fit: cover;
  }

  .lazyload-container {
    height: 100%;
  }


  .work-text{
    -webkit-transition: color 1000ms linear;
-moz-transition: color 1000ms linear;
-o-transition: color 1000ms linear;
-ms-transition: color 1000ms linear;
transition: color 1000ms linear;
    color: black;
  }
  
  .placeholder {
    transition: all 1s ease-in-out;
  }
  
