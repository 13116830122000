#contact {
  contain: paint;
scroll-behavior: smooth;
}

.bg-off {
    background-color: #d9e5d6;
}

.autofill-bg {
    background-color: transparent !important;
  }

  
  .feild-area {
    position: absolute;
    left: 15rem;
    width: 60%;
  }

  input:-webkit-autofill,
  input:-webkit-autofill:hover, 
  input:-webkit-autofill:focus, 
  input:-webkit-autofill:active{
      -webkit-box-shadow:rgba(255, 255, 255, 0.1) !important;
  }


  .submit-btn:hover {
    background-color: #0C9EEA;
}
@media (max-width: 550px) {   
  .dont {
      font-size: 4rem;
  line-height: 1;
  padding-top: 25vh;
  }
  .feild-area {
    position: absolute;
    left: 6rem;
    width: 55%;
}

form .text-2xl {
  font-size: 0.9rem;
  line-height: 1;
}
form .text-sm {
  font-size: 0.65rem;
  line-height: 1;
  max-width: 4rem;
  word-break: break-word;
}
}

@media (max-width: 375px) {   

  .feild-area {
    position: absolute;
    left: 6rem;
    width: 50%;
}

form .text-2xl {
  font-size: 0.8rem;
  line-height: 1;
}
form .text-sm {
  font-size: 0.5rem;
  line-height: 1;
  max-width: 3rem;
  word-break: break-word;
}
}

@media (max-width: 320px) {   
.whether{
  width: 15rem;
}

.feild-area {
  position: absolute;
  left: 5rem;
  width: 50%;
}

form .text-2xl {
font-size: 0.8rem;
line-height: 1;
}
form .text-sm {
font-size: 0.5rem;
line-height: 1;
max-width: 3rem;
word-break: break-word;
}
}