@font-face {
  font-family: 'Dolce';
  src: local('Dolce'), url("../../fonts/Dolce Vita Heavy Bold.ttf") format('truetype');
  font-display: swap;
  font-style: normal;
}


@font-face {
  font-family: 'Dirty-line';
  src: local('Dirty-line'), url("../../fonts/Dirtyline.ttf") format('truetype');
  font-display: swap;
  font-style: normal;
}

.App {
  text-align: center;
  contain: paint;
  -webkit-user-select: none;
-khtml-user-select: none;
-moz-user-select: none;
-ms-user-select: none;
-o-user-select: none;
user-select: none;
scroll-behavior: smooth;
background-color: #f6f4f3;
-webkit-transition: background-color 1000ms linear;
-moz-transition: background-color 1000ms linear;
-o-transition: background-color 1000ms linear;
-ms-transition: background-color 1000ms linear;
transition: background-color 1000ms linear;
}

.Dolce {
  font-family: Dolce;
}

.fixedT {
  -webkit-transform: translate3d(0,0,0);
transform: translate3d(0,0,0);
position: fixed;
}