  //contact
  #footer {
    font-family: Dolce;
    padding-top: 5vh;
  }

//new ticker
#infinite {
    font-family: Dolce;
    overflow: hidden;
  }
  
  #infinite-fx{
    font-size: 16.6666666667vw;
    position: relative;
    display: flex;
    white-space: nowrap;
    will-change: transform;
  }
  
  #infinite-fx > span:nth-child(1) {
    animation: animate 40s -0s linear infinite;
    display: inline-flex;
    align-items:center;
    padding: 0 2rem;
  }
  
  #infinite-fx > span:nth-child(2){
    animation: animate2 40s -20s linear infinite;
    display: inline-flex;
    align-items:center;
    padding: 0 2rem;
  }
  
  @keyframes animate {
    0% {
      transform: translateX(100%);
    }
    100% {
      transform: translateX(-100%);
    }
  }
  
  @keyframes animate2 {
    0% {
      transform: translateX(0);
    }
    100% {
      transform: translateX(-200%);
    }
  }
    
  .contact {
    display: flex;
    flex-direction: row;
    padding-left: 2.5rem;
    padding-right: 2.5rem;
    gap: 15rem;
    font-size: 2rem;
  }
  
  .contact a:hover {
    opacity: 0.6;
  }
  
  .copyright{
    padding-top: 6rem;
    padding-bottom: 2rem;
  }

  .active {
    opacity: 0.5;
  }

  .text-white-diff{
    color: #f6f4f3;
  }