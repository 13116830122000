.studio {
    -webkit-user-select: none;
    -khtml-user-select: none;
    -moz-user-select: none;
    -ms-user-select: none;
    -o-user-select: none;
    user-select: none;
}

.text-off {
    color: #d9e5d6;
}

.custom-width {
  width: 35rem;
}

.branding li, .digital li, .marketing li {
padding-top: 0.2em;
font-size: 0.9em;
}

.say-goodbye {
    font-size: 10rem;
    line-height: 9rem;
}

.work-with-us {
    background-image: linear-gradient(
      to left,
      #f6f4f3,
      #f6f4f3 50%,
      #ea580c 50%,
       
    );
    background-size: 200% 100%;
    font-family: Dolce;
    background-position: -100%;
    display: inline-block;
    padding: 5px 0;
    position: relative;
    background-clip: text;
    -webkit-background-clip: text;
    -webkit-text-fill-color: transparent;
    transition: all 0.5s ease-in-out;
  }
  
  .work-with-us:hover {
   background-position: 0;
  }

  .ul-studio {
    font-size: 0.9rem;
  }
    
  @media (max-width: 1024px) {   
    .say-goodbye {
        font-size: 9rem;
    line-height: 1;
    }
  }

  @media (max-width: 768px) {   
    .say-goodbye {
        font-size: 7rem;
    line-height: 1;
    }
    .ready {
      font-size: 3rem;
    }
  }

  @media (max-width: 550px) {   
    .say-goodbye {
        font-size: 4rem;
    line-height: 1;
    padding-top: 25vh;
    }
    .custom-width {
      width: calc(100vw - 2rem);
    }
    .connect {
      font-size: 1rem;
      line-height: 1;
    }
    .ready {
      font-size: 2rem;
    }
    .bridging {
      font-size: 2rem;
    }
    .branding h2, .digital h2, .marketing h2 {
      width: 5rem;
      line-height: 2rem;
      }
  }
