@font-face {
    font-family: 'Dolce';
    src: local('Dolce'), url("../../fonts/Dolce Vita Heavy Bold.ttf") format('truetype');
    font-display: swap;
    font-style: normal;
  }
  
  @font-face {
    font-family: 'Dirty-line';
    src: local('Dirty-line'), url("../../fonts/Dirtyline.ttf") format('truetype');
    font-display: swap;
    font-style: normal;
  }

//home
.home {
    height:100vh;
    padding-right: 3rem;
    padding-left: 3rem;
    position: relative;
    display: flex;
    align-items: flex-end;
    }
    
    .mission {
  overflow: hidden;
  display: -webkit-box;
  -webkit-box-orient: vertical;
  -webkit-line-clamp: 4;
      font-family: Dolce;
      text-align: left;
      font-size: calc(5.8rem + 1vw);
      mix-blend-mode: difference;
      color: #f6f4f3;
      z-index: 10;
      width: 100%;
      line-height: 1em;
    }
    
.mission span {
  margin: 0; // remove any default margin
  padding: 0; // remove any default padding
  line-height: 1; // set the line-height of your text
}

    .line{
      display: inline-block;
      line-height: 1em;
    }
    
    .dirtyline {
      font-family: Dirty-line;
      font-size: calc(5.2rem + 1vw);
    }
    
    .ar {
      margin: 0;
      position: relative;
      height: 0em;
    }
    
    .a {
      display: block;
      opacity: 1;
    }
    
    .ace {
      transform: translateY(calc(-83px - 1vw));
      height: calc(63px + 1vw);
      color: #f6f4f3;
      stroke: none;
    }

    .astro1 {
  position: absolute;
  z-index: 1;
  transition: all ease-out 3s;
  -webkit-transition: all 3s ease-out;
  -moz-transition: all 3s ease-out;
  -o-transition: all 3s ease-out;
  -ms-transition: all 3s ease-out;
}
.astro2 {
  position: absolute;
  z-index: 0;
}

.expo1 {
  position: absolute;
  max-width: 24rem;
  transform: translate(694px, -278px);
  transition: all ease-out 3s;
  -webkit-transition: all 3s ease-out;
  -moz-transition: all 3s ease-out;
  -o-transition: all 3s ease-out;
  -ms-transition: all 3s ease-out;
}

.expo2 {
  position: absolute;
  max-width: 24rem;
  transform: translate(694px, -278px);
}

.mission:hover + .expo1 {
  opacity: 0;
}
    
.expo1:hover {
    opacity: 0;
}

.astronaut img{
  position: absolute;
  top: calc(50% + 1rem);
  transform: translate(0, -50%);
  right: 12.7rem;
  width: 25rem;
  object-fit: cover;
  overflow: hidden;
}

.astronaut > img:nth-child(1) {
  z-index: 1;
  transition: all 4s ease-in-out;
}

.astronaut > img:nth-child(2) {
  z-index: 2;
  opacity: 0;
  transition: all 4s ease-in-out;
}

.astronaut > img:nth-child(2):hover {
 opacity: 1;
}

.mission:hover + .astronaut > img:nth-child(2) {
  opacity: 1;
}