@media (max-width: 1300px) {   
    .mission {
        font-size: calc(5.3rem + 1vw);
    }
    .dirtyline {
        font-size: calc(4.9rem + 1vw);
    }
    .ace {
        height: calc(60px + 1vw);
        transform: translateY(calc(-80px - 1vw));
    }

    .hs-wrapper {
        height: 70vh;
      }

      .ps h1 {
        font-size: calc(2.8rem + 1vw);
        line-height: calc(2.6rem + 1vw);
    }

    .static {
        height: 40vh;
    }

    .work-tv {
        font-size: 3rem;
    }

  }

  @media (max-width: 1024px) {   
    .home {
        padding-top: 20rem;
    }
    .mission1, .mission2 {
        margin-top: 20rem;
    }

    .mission {
        font-size: calc(4.2rem + 1vw);
    }
    .dirtyline {
        font-size: calc(3.8rem + 1vw);
    }
    .ace {
        height: calc(50px + 1vw);
        transform: translateY(calc(-66px - 1vw));
    }



      .ps h1 {
        font-size: calc(2.1rem + 1vw);
        line-height: calc(2rem + 1vw);
    }

    .static {
        height: 33vh;
    }

    .work-tv {
        font-size: 3rem;
    }

    .blog-content {
        width: 25rem;
    }
    .hs-wrapper {
        height: 55vh;
    }
  }

  @media (max-width: 920px) {   
    .home {
        padding-top: 18rem;
    }

    .mission {
        font-size: calc(3rem + 1vw);
    }
    .dirtyline {
        font-size: calc(2.7rem + 1vw);
    }
    .ace {
        height: calc(35px + 1vw);
        transform: translateY(calc(-45px - 1vw));
    }

    .hs-wrapper {
        height: auto;
        width: calc(100vw - 6rem);
      }

      .ps {
        display: flex;
        align-items: center;
      }

      .ps h1 {
        font-size: calc(2rem + 1vw);
        line-height: calc(1.7rem + 1vw);
    }

    .view-all {
        height: 2rem;
    }
    
    .tv-fx {
        display: none;
    }

    .static {
        display: none;
    }

    .work-tv {
        display: none;
    }
    .w-work {
        height: 200vh;
    }
    .blog-content {
        width: 20rem;
    }
    .mission1 {
        right: 15rem;
        margin-top: 18rem;
      }
      .mission2 {
        margin-top: 18rem;
      }

  }

  @media (max-width: 768px) {   
    .home {
    padding-top: calc(50% - 2rem);
    }

    .mission {
        font-size: calc(3rem + 1vw);
    }
    .dirtyline {
        font-size: calc(2.7rem + 1vw);
    }
    .ace {
        height: calc(35px + 1vw);
        transform: translateY(calc(-45px - 1vw));
    }

    .fadein{
        display: none;
    }
    .contact {
        padding-left: 3rem;
        padding-right: 3rem;
        gap: 5rem;
        font-size: 1.2rem;
    }

    .nav-sideIcon {
        display: flex;
    }

  #navbar li {
    display: none;
  }

  .nav-right {
    display: none;
  }

  .mission1 {
    right: 0;
    left: 0;
    margin-top: 36rem;
  }

  .mission2 {
    right: 0;
    left: 0;
    margin-top: 39rem;
    width: auto;
  }

  .w-work {
    height: 200vh;
  }

  .blog-content {
    width: 15rem;
  }

  .a-work {
    display: none;
  }
  }

  @media (max-width: 550px) {   
    #navbar ul {
        padding-right:1rem;
        padding-left:1rem;
    }
    .px-12 {
        padding-left: 1rem;
        padding-right: 1rem;
    }
    .mx-12 {
        margin-left: 1rem;
        margin-right: 1rem;
    }
    .text-xs {
        font-size: 0.55rem;
    }
    .mission {
        font-size: calc(1.89rem + 0.5vw);
        padding-bottom: 0;
    }
    .dirtyline {
        font-size: calc(1.7rem + 0.5vw);
    }
    .ace {
        height: calc(24px + 1vw);
        transform: translateY(calc(-30px - 1vw));
    }

    #App > div.home > div.mission > span.letter.fadein {
        display: none;
    }

    .home {
        padding-right: 1rem;
        padding-left: 1rem;
        justify-content: normal;
        display: flex;
    align-items: flex-end;
    padding-bottom: 12rem;
    }


    .hs-wrapper {
        height: 40vh;
        width: calc(100vw - 2rem);
      }

      .ps {
        display: flex;
        align-items: center;
        padding-top: 0rem;
        padding-left: 1rem;
    padding-right: 1rem;
      }

      .ps h1 {
        font-size: calc(2rem + 1vw);
        line-height: calc(1.7rem + 1vw);
    }

    .w-svg {
        scale: 1.3;
    }
    .view-all {
        height: 2rem;
        width: 6rem;
    }
    
    .tv-fx {
        display: none;
    }

    .static {
        display: none;
    }

    .work-tv {
        display: none;
    }
    .w-work {
        height: 120vh;
    }
    .contact {
        padding-left: 1rem;
        padding-right: 1rem;
        gap: 5rem;
        font-size: 1.4rem;
    }
    #footer {
        padding-top: 5vh;
    }

    #footer > div.contact > ul:nth-child(3) {
        display: none;
    }
    .nav-right {
        gap: 4rem;
    }
    .nav-logo {
        width: 3rem;
    }
    .hs-wrapper video {
        height: 40vh;
    }
    .nav-items, .nav-info{
        display: none;
    }
    .side-menu{
        display: block;
    }
    .blog-content {
        width: 8rem;
        max-height: 6.5rem;
        line-height: 1.1;
    }
    .text-xl {
        font-size: 17px;
        line-height: 16px;
    }
    .h-96 {
        height: 12rem;
    }

    .pl-10 {
        padding-left:1rem
    }
    .mission1 {
        right: 0;
        left: 0;
        bottom: 0;
        padding-bottom: 9rem;
        top: auto;
      }
    
      .mission2 {
        right: 0;
        left: 0;
        bottom: 0;
        padding-bottom: 5rem;
        top: auto;
        line-height: 1rem;
      }
      .prev-next {
        top: 1em;
        bottom: auto;
      }
      .workHome {
        font-size: 5rem;
    }
  }

  @media (max-width: 400px) {   
    .mission {
        font-size: calc(1.6rem + 1vw);
    }
    .dirtyline {
        font-size: calc(1.4rem + 1vw);
    }
    .ace {
        height: calc(20px + 1vw);
        transform: translateY(calc(-25.5px - 1vw));
    }
    .ps h1 {
        font-size: calc(1.9rem + 1vw);
        line-height: calc(1.5rem + 1vw);
    }
  }

  
  @media (max-width: 360px) {   
    .mission {
        font-size: calc(1.33rem + 1vw);
    }
    .dirtyline {
        font-size: calc(1.19rem + 1vw);
    }
    .ace {
        height: calc(16px + 1vw);
        transform: translateY(calc(-20px - 1vw));
    }

    .contact {
        gap: 4rem;
        font-size: 1.2rem;
    }
    .ps h1 {
        font-size: calc(1.62rem + 1vw);
        line-height: calc(1.5rem + 1vw);
    }
    .blog-content {
        width: 8rem;
        max-height: 5.8rem;
        line-height: 1.1;
    }
  }
  @media (max-width: 280px) {   
    .mission {
        font-size: calc(1.18rem + 1vw);
    }
    .dirtyline {
        font-size: calc(1rem + 1vw);
    }
    .ace {
        height: calc(16px + 1vw);
        transform: translateY(calc(-20px - 1vw));
    }
  }