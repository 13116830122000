.h-blog {
    height: 80vh;
}

.text-overflow::after{
    content:'...';
    position: absolute;
    right: 0;
    bottom: 0;
  }

  .post-content1 > div  {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
  }

.post-content1 > div > p {
    padding-top: 1.5rem;
    font-size: 1.3rem;
    width: 70%;
  }

  .h-img-blog{
    height: 80vh;
  }

  .next-post-image {
    width: 100%;
aspect-ratio: 16/9;
    object-fit: cover;
    margin-left: auto;
    padding-right: 3rem;
  }

  .next-post-info {
    font-size: 2rem;
text-align: left;
word-break: keep-all;
padding-right: 3rem;
  }

  .blog-content {
    width: 30rem;
    max-height: 17.5rem;
  }


  @media (max-width: 425px) { 
    .post-content1 > div > p {
      padding-top: 1.5rem;
      font-size: 1.3rem;
      width: 100%;
    }
    .text-5xl {
      font-size: 2rem;
      line-height: 1;
    }
    .next-post-image {
      padding-right: 1rem;
  }
  .next-post-info {
    padding-right: 1rem;
    font-size: 1.5rem;
}
  }